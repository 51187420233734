import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import Seo from '@elements/Seo';
import { getDomain } from '@helpers/domains';
import { useEnvContext } from '@helpers/envProvider';
import { logout } from '@helpers/logout';
import matchPathname from '@helpers/matchPathname';
import CompactLayout from '@layout/CompactLayout';
import Layout from '@layout/Layout';
import LoginSection from '@sections/LoginSection';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login({ isInvite, routerQuery, readerUrl }) {
  const router = useRouter();
  const { deletedAccount, setUserStore, isLoggedIn } = userStore();
  const { environment } = useEnvContext();
  const domain = getDomain(environment);
  async function displayToastIfAccountDeleted() {
    if (deletedAccount) {
      toast.success('Account verwijderd.', {
        position: 'top-right',
        icon: <Icon iconName="check" color={'text-green-500'} />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setUserStore(
        false,
        '',
        '',
        [],
        [],
        [],
        false,
        false,
        false,
        '',
        false,
        '',
        '',
        false,
        '',
        '',
        '',
        '',
      );
    }
  }

  function checkIfRefresh() {
    if (routerQuery.refresh) {
      logout(setUserStore);
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    displayToastIfAccountDeleted();
    checkIfRefresh();
    // Check if user is logged in then redirect home
    if (isLoggedIn && routerQuery.refresh != 'true') {
      router.replace('/');
    }
  }, [routerQuery.refresh]);

  if (isInvite) {
    return (
      <CompactLayout>
        <LoginSection isInvite={isInvite} routerQuery={router.query} />
      </CompactLayout>
    );
  }

  return (
    <Layout>
      <Seo
        title={`Inloggen bij BouwZo`}
        description="Log in bij BouwZo: Toegang tot je profiel om je favoriete publicaties en persoonlijke instellingen te beheren. Nog geen profiel? Maak er eenvoudig een aan."
        url={`https://${domain}${router.asPath}`}
      />
      <LoginSection isInvite={isInvite} routerQuery={router.query} readerUrl={readerUrl} />
    </Layout>
  );
}

export async function getServerSideProps(context) {
  const { referer } = context.req.headers;
  const { inviteCode, isValid, refresh } = context.query;

  // If the previous url is from the reader page add a redirect to the url
  let readerUrl = '';
  if (referer && referer.indexOf('reader') !== -1) {
    readerUrl = matchPathname(referer)[1];
  }

  let isInvite = false;
  if (inviteCode && isValid) {
    isInvite = true;
  }

  return {
    props: {
      isInvite,
      readerUrl,
      routerQuery: {
        inviteCode: inviteCode || null,
        isValid: isValid || null,
        refresh: refresh || null,
      },
    },
  };
}
